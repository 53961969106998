


















































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import CdrDuration from '../../components/sessions/CdrDuration.vue'
import ChargeSessionApi from '@/services/ChargeSessionApi'
import GenericTablePagination from '../../components/_base/BcDataTable/GenericTablePagination.vue'
import SessionDetails from '../../components/sessions/SessionDetails.vue'
import PaginationInterface from '../../typings/PaginationInterface'
import AutocompleteTokenList from '@/components/tokens/AutocompleteTokenList.vue'
import AutocompleteChargePointList from '@/components/chargepoint/AutocompleteChargePointList.vue'
import DateRange from '@/components/common/DateRange.vue'
import downloadExcel, { ExcelData } from '../../functions/downloadExcel'
import moment from 'moment'
import { calcDiff } from './utils'
import BcBtnUx from '@/components/_base/BcBtnUx'
import { DataTableHeader } from 'vuetify'
import { mapGetters } from 'vuex'
import {
  RelationFilterInterface,
  RelationTypes
} from '@/typings/RelationFilterInterface'
import { isAllowedToUseGenericRelationFilter } from '@/functions/genericRelationFilter'
import { GENERIC_FILTER_CHARGING_HISTORY } from '@/mocks/featureFlags'
import {
  ChargeSession,
  ChargeSessionFilterInterface
} from '@/typings/ChargeSession'
import DefaultWithPaginationResponseInterface from '@/typings/DefaultWithPaginationResponseInterface'
import { HeadRows } from '@/typings/OptionsDataTable'

type SelectedFilter = string | null | undefined

@Component({
  components: {
    DateRange,
    CdrDuration,
    GenericTablePagination,
    SessionDetails,
    AutocompleteTokenList,
    AutocompleteChargePointList,
    BcBtnUx
  },
  filters: {
    tokWh(val: string | number) {
      if (typeof val === 'string') {
        val = Number(val)
      }
      val = val / 10
      val = Math.round(val)
      return val / 100
    },
    formatCustomReference(val: string) {
      if (val) {
        return val
      }
      return ' - '
    },
    toPass(val: string) {
      if (val === '') {
        return 'Non-Blue Corner Pass'
      }
      return val
    }
  },
  computed: {
    ...mapGetters({
      genericRelationFilter: 'genericRelationFilter/currentRelation'
    })
  }
})
export default class ChargeSessions extends Vue {
  delimiter = ','
  genericRelationFilter!: RelationFilterInterface
  loading = false
  exportLoading = false
  searchDT = false
  data: ChargeSession[] = []
  exportDTA: ChargeSession[] = []
  dateArray: Array<string> = []
  dialog = false
  selected = 0
  selectedSession: ChargeSession[] = []
  filterByToken: SelectedFilter = null
  filterByChargePoint: SelectedFilter = null
  filterByRangeDate: SelectedFilter = null
  pagination: PaginationInterface = {
    pagesize: 10,
    pagenumber: 1,
    type: 0
  }

  headers: DataTableHeader[] = [
    {
      text: this.t('$vuetify.myChargeSessions.location'),
      value: 'Location',
      sortable: false,
      width: '20%'
    },
    {
      text: this.t('$vuetify.myChargeSessions.chargepoint'),
      value: 'Chargepoint',
      sortable: false,
      width: '20%'
    },
    {
      text: this.t('$vuetify.myChargeSessions.chargePass'),
      value: 'Charge Pass',
      sortable: false,
      width: '20%'
    },
    {
      text: this.t('$vuetify.myChargeSessions.customerReference'),
      value: 'Customer Reference',
      sortable: false,
      width: '15%'
    },
    {
      text: this.t('$vuetify.myChargeSessions.startStopSession'),
      value: 'Start/Stop session',
      sortable: false,
      width: '20%'
    },
    {
      text: this.t('$vuetify.myChargeSessions.sessionDuration'),
      value: 'state',
      sortable: false,
      width: '20%'
    }
  ]

  headersExcel: DataTableHeader[] = [
    {
      text: this.t('$vuetify.myChargeSessions.excel.country'),
      value: 'Location',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeSessions.excel.city'),
      value: 'Location',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeSessions.excel.zip'),
      value: 'Location',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeSessions.excel.street'),
      value: 'Location',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeSessions.excel.housenumber'),
      value: 'Location',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeSessions.excel.chargepoint'),
      value: 'Chargepoint',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeSessions.excel.chargePass'),
      value: 'Charge Pass',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeSessions.excel.customerReference'),
      value: 'CustomerReference',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeSessions.excel.startDate'),
      value: 'Start/Stop session',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeSessions.excel.endDate'),
      value: 'Start/Stop session',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeSessions.excel.chargingTime'),
      value: 'Start/Stop session',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeSessions.excel.parkingTime'),
      value: 'Start/Stop session',
      sortable: false
    },
    {
      text: this.t('$vuetify.myChargeSessions.excel.consumption'),
      value: 'state',
      sortable: false
    }
  ]

  mounted() {
    if (this.$route.query.chargepoint) {
      this.filterByChargePoint = this.$route.query.chargepoint as string
    }
    if (this.$route.query.token) {
      this.filterByToken = this.$route.query.token as string
    }
    this.fetchSessions()
  }

  get tableData() {
    const rows: Array<Array<string>> = []
    this.selectedSession = this.exportDTA
    for (const element of this.selectedSession) {
      const row: Array<string> = []
      row.push(`${element.ChargePoint.ChargeLocationCountry}`)
      row.push(`${element.ChargePoint.ChargeLocationCity}`)
      row.push(`${element.ChargePoint.ChargeLocationZip}`)
      row.push(`${element.ChargePoint.ChargeLocationStreet}`)
      row.push(`${element.ChargePoint.ChargeLocationHouseNumber}`)
      row.push(`${element.chargepointlabel}`)
      row.push(`${element.User.PrintedNumber}`)
      let consump = element.Consumption
      if (typeof consump === 'string') {
        consump = Number(element.Consumption)
      }
      const start = element.ChargeSession.Start
      const end = element.ChargeSession.End
      row.push(`${element.CustomerReference}`)
      row.push(moment(start).format('DD/MM/YYYY HH:mm:ss'))
      row.push(moment(end).format('DD/MM/YYYY HH:mm:ss'))
      consump = consump / 10
      consump = Math.round(consump)
      consump = consump / 100
      const chargingTime = this.calcDiff(start, end)
      row.push(`${chargingTime}`)
      row.push(`${element.Rotation}`)
      row.push(consump.toString())
      rows.push(row)
    }

    return rows
  }

  get head() {
    const rtn: Array<HeadRows> = []
    for (const element of this.headersExcel) {
      rtn.push({
        name: `${element.text}`,
        totalsRowLabel: 'sum:',
        filterButton: true
      })
    }
    return rtn
  }

  async fetchSessions() {
    try {
      this.data = []
      this.loading = true
      const filter: ChargeSessionFilterInterface = {}
      const isAllowed: boolean = isAllowedToUseGenericRelationFilter({
        store: this.$store,
        route: this.$route,
        path: '/my/chargesession',
        flag: GENERIC_FILTER_CHARGING_HISTORY
      })
      if (this.filterByToken && this.filterByToken.length > 0) {
        filter.token = this.filterByToken
      }
      if (this.filterByChargePoint && this.filterByChargePoint.length > 0) {
        filter.chargepoint = this.filterByChargePoint
      }

      if (this.filterByRangeDate) {
        filter.sessionstart = { op: 'GE', value: this.dateArray[0] }
        const enddate = moment(this.dateArray[1]).add(1, 'd').format('YYYY-MM-DD');
        filter.sessionend = { op: 'LT', value: enddate }
      }

      let relationId
      let relationContactId
      if (isAllowed) {
        const selectedRelation = this.genericRelationFilter
        if (
          selectedRelation &&
          selectedRelation.Type === RelationTypes.relationContact
        ) {
          relationContactId = selectedRelation.Id
        } else {
          relationId = selectedRelation.Id
        }
      }

      const data = (await new ChargeSessionApi().filtered({
        filterProps: this.pagination,
        filter,
        relationId,
        relationContactId
      })) as DefaultWithPaginationResponseInterface<ChargeSession[]>
      this.loading = false

      if (data && !this._.isEmpty(data.data)) {
        this.data = data.data
        data.pagination.pagenumber = this.pagination.pagenumber
        data.pagination.pagesize = this.pagination.pagesize
        this.pagination = data.pagination
      }
    } catch (err) {
      this.loading = false
    }
  }

  async exportDownload(delimiter?: ';' | ',') {
    try {
      this.exportLoading = true
      const filter: ChargeSessionFilterInterface = {}
      const isAllowed: boolean = isAllowedToUseGenericRelationFilter({
        store: this.$store,
        route: this.$route,
        path: '/my/chargesession',
        flag: GENERIC_FILTER_CHARGING_HISTORY
      })
      if (this.filterByToken) {
        filter.token = this.filterByToken
      }
      if (this.filterByChargePoint) {
        filter.chargepoint = this.filterByChargePoint
      }

      if (this.filterByRangeDate) {
        filter.sessionstart = { op: 'GE', value: this.dateArray[0] }
        const enddate = moment(this.dateArray[1]).add(1, 'd').format('YYYY-MM-DD');
        filter.sessionend = { op: 'LT', value: enddate }
      }

      let relationId
      let relationContactId
      if (isAllowed) {
        const selectedRelation = this.genericRelationFilter
        if (
          selectedRelation &&
          selectedRelation.Type === RelationTypes.relationContact
        ) {
          relationContactId = selectedRelation.Id
        } else {
          relationId = selectedRelation.Id
        }
      }

      const pagination: PaginationInterface = {
        pagesize: 100000,
        pagenumber: 1,
        type: 0
      }
      const data = (await new ChargeSessionApi().filtered({
        filterProps: pagination,
        filter,
        relationId,
        relationContactId
      })) as DefaultWithPaginationResponseInterface<ChargeSession[]>
      this.exportLoading = false
      this.exportDTA = data.data
      const dta: ExcelData = {
        headers: this.head,
        data: this.tableData,
        options: {
          name: 'Tokens',
          exportFileName: 'Charge Sessions - List',
          fileExtension: 'xlsx',
          csvDelimiter: delimiter
        }
      }
      downloadExcel(dta)
    } catch (err) {
      console.log(err)
    }
  }

  calcDiff(start: string, end: string, forExcel = true) {
    return calcDiff(start, end, forExcel)
  }

  @Watch('pagination', { deep: true })
  paginationOnChanged(val: PaginationInterface, oldVal: PaginationInterface) {
    if (
      val.pagesize !== oldVal.pagesize ||
      val.pagenumber !== oldVal.pagenumber
    ) {
      this.fetchSessions()
    }
  }

  @Watch('filterByToken')
  @Watch('filterByChargePoint')
  propOnChanged() {
    this.pagination = {
      pagesize: 10,
      pagenumber: 1,
      type: 0
    }
    this.fetchSessions()
  }

  searchFilter() {
    this.pagination = {
      pagesize: 10,
      pagenumber: 1,
      type: 0
    }
    if (!this._.isEmpty(this.dateArray) && this.dateArray.length === 2) {
      const enddate = moment(this.dateArray[1]).add(1, 'd').format('YYYY-MM-DD');
      this.filterByRangeDate = `{"and": [{"sessionstart":{"op": "GE", "value": "${this.dateArray[0]}"}}, {"sessionend": {"op": "LE", "value": "${(enddate)}"}}] }`
      this.fetchSessions()
      this.searchDT = false
    } else {
      if (this._.isEmpty(this.dateArray)) {
        this.filterByRangeDate = null
        this.fetchSessions()
      }
    }
  }

  @Watch('genericRelationFilter')
  genericRelationFilterChanged() {
    this.fetchSessions()
  }
}
