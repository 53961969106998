











































import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync } from 'vue-property-decorator'

@Component
export default class AutocompleteChargePointList extends Vue {
  @PropSync('dateArray', { type: Array }) dates!: string[] | undefined
  @PropSync('searchDT', { type: Boolean }) searchDate!: boolean
  showPicker = false

  get dateRangeText() {
    if (this.dates) {
      return this.dates.join(' ~ ')
    }
  }

  reset() {
    this.dates = []
    this.showPicker = false
    this.$emit('searchFilter')
  }

  searchFilter() {
    this.$emit('searchFilter')
  }

  get validateDate() {
    if (this.dates) {
      return this.dates.length < 2
    }
    return false
  }

  save(dates: string) {
    // @ts-ignore
    this.$refs.menu.save(dates)
    this.searchDate = true
    this.searchFilter()
  }
}
