import BaseApi from './BaseApi'

export default class RelationApi extends BaseApi {
  constructor (type = 0) {
    super('Subscription', type)
  }

  async getSubscriptions () {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/subscription/BCO/list`,
      method: 'get',
      params: {
        type: 0,
        cusId: 4812,
        pagesize: 1000,
        pagenumber: -1
      }
    }
    return await this.getApi(options)
  }

  async getSubscription (code: any) {
    code = code.toString().replace('#', '%23')
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/subscriptiontype/BCO/${code}`,
      method: 'get'
    }
    return await this.getApi(options)
  }


  async details (id: string | number) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/details/${id}`,
      method: 'get'
    }
    return await this.getApi(options)
  }

  async loadSessies (id: string | number) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/session/BCO/lasttenbytoken/${id}`,
      method: 'get'
    }
    return await this.getApi(options)
  }

  async list (relationContactId?: string, relationId?: string) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/list`,
      method: 'get',
      params: {
        type: 0,
        pagesize: 10,
        pagenumber: -1,
        relationcontactid: relationContactId,
        relid: relationId
      }
    }
    return await this.getApi(options)
  }

  async getMap () {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/locations/1.2/BCO/maplist/public?countries=["BE"]&roaming=0`,
      method: 'get'
    }
    return await this.getApi(options)
  }

  async activate (id: string | number) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/activate/${id}`,
      method: 'put'
    }
    return await this.putApi(options)
  }

  async deactivate (id: string | number) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/deactivate/${id}`,
      method: 'put'
    }
    return await this.putApi(options)
  }

  async addCard (payload: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/link`,
      method: 'post'
    }
    return await this.postApi(options)
  }

  async change (id: string | number, payload: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/subscription/BCO/${id}`,
      data: payload,
      method: 'put'
    }
    return await this.putApi(options)
  }

  async changeDriver (id: string | number, payload: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/subscription/BCO/${id}`,
      data: payload,
      method: 'put'
    }
    return await this.putApi(options)
  }

  async changeSubscription (payload: any) {
    const options = {
      url: `${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/token/BCO/link`,
      data: payload,
      method: 'post'
    }
    return await this.postApi(options)
  }

  async getTenLastSessions (token: string | number) {
    const dt = await this.getApi(`${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/session/BCO/lasttenbytoken/${token}`) as any
    return dt.data
  }

  async getLastAuthorize (id: string | number) {
    const dt = await this.getApi(`${process.env.VUE_APP_API_ENDPOINT}/${process.env.VUE_APP_API_VERSION}/authorize/BCO/getlast/${id}`) as any
    return dt.data
  }
}
