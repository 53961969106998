



















































import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'
import ChargePointApi from '@/services/ChargePointApi'
import { ChargePoint } from '@/typings/ChargePoint'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'
import { isAllowedToUseGenericRelationFilter } from '@/functions/genericRelationFilter'
import {
  RelationFilterInterface,
  RelationTypes
} from '@/typings/RelationFilterInterface'
import { mapGetters } from 'vuex'
import { GENERIC_FILTER_CHARGING_HISTORY } from '@/mocks/featureFlags'

@Component({
  computed: {
    ...mapGetters({
      genericRelationFilter: 'genericRelationFilter/currentRelation'
    })
  }
})
export default class AutocompleteChargePointList extends Vue {
  @PropSync('selected', { type: Array }) selectedSynced!: string[] | undefined
  tokens: Array<ChargePoint> = []
  loaded = false
  loading = false
  search = ''
  genericRelationFilter!: RelationFilterInterface

  mounted() {
    this.$nextTick(() => {
      if (this.selectedSynced) {
        this.getTokens()
      }
    })
  }

  doLoading() {
    if (!this.loaded) {
      this.getTokens()
    }
  }

  remove(item: string) {
    if (this.selectedSynced) {
      const index = this.selectedSynced.indexOf(item)
      if (index >= 0) this.selectedSynced.splice(index, 1)
    }
  }

  checkIfSelected(item: string) {
    if (this.selectedSynced) {
      return this.selectedSynced.indexOf(item) > -1
    }
    return false
  }

  genTitle(item: ChargePoint) {
    return `${item.chargeboxidentifier}`
  }

  genSubTitle(item: ChargePoint) {
    let text = ''
    if (item.placements.length > 0) {
      if (item.placements[0]) {
        text = item.placements[0].address + ' '
      }
    }
    text += item.description
    return text
  }

  async getTokens() {
    this.loading = true
    const isAllowed: boolean = isAllowedToUseGenericRelationFilter({
      store: this.$store,
      route: this.$route,
      path: '/my/chargesession',
      flag: GENERIC_FILTER_CHARGING_HISTORY
    })
    let relationId
    let relationContactId
    if (isAllowed) {
      const selectedRelation = this.genericRelationFilter
      if (
        selectedRelation &&
        selectedRelation.Type === RelationTypes.relationContact
      ) {
        relationContactId = selectedRelation.Id
      } else {
        relationId = selectedRelation.Id
      }
    }
    const dt =
      (await new ChargePointApi().myChargers('', relationContactId,relationId)) as DefaultResponseInterface<
        ChargePoint[]
      >
    this.loading = false
    this.loaded = true
    this.tokens = dt.data
  }

  @Watch('genericRelationFilter')
  genericRelationFilterChanged() {
    this.getTokens()
  }
}
