import RelationInterface from '@/typings/ApiTypings/RelationInterface'

export enum RelationTypes {
  relationContact = 'relationcontact',
  relation = 'relation'
}

export interface RelationFilterInterface {
  Id: string
  Description: string
  Relation: RelationInterface
  Type: RelationTypes
  Value: string
}
