




















































import Vue from 'vue'
import Component from 'vue-class-component'
import { PropSync, Watch } from 'vue-property-decorator'
import SubscriptionApi from '@/services/SubscriptionApi'
import { Tokens } from '@/typings/Tokens'
import DefaultResponseInterface from '@/typings/DefaultResponseInterface'
import { GENERIC_FILTER_CHARGING_HISTORY } from '@/mocks/featureFlags'
import { isAllowedToUseGenericRelationFilter } from '@/functions/genericRelationFilter'
import {
  RelationFilterInterface,
  RelationTypes
} from '@/typings/RelationFilterInterface'
import { mapGetters } from 'vuex'

@Component({
  computed: {
    ...mapGetters({
      genericRelationFilter: 'genericRelationFilter/currentRelation'
    })
  }
})
export default class AutocompleteTokenList extends Vue {
  @PropSync('selected', { type: Array }) selectedSynced!: string[] | undefined

  tokens: Array<Tokens> = []
  loaded = false
  loading = false
  search = ''
  multiple = true
  genericRelationFilter!: RelationFilterInterface

  mounted() {
    this.$nextTick(() => {
      if (this.selectedSynced) {
        this.getTokens()
      }
    })
  }

  checkIfSelected(item: string) {
    if (this.selectedSynced) {
      return this.selectedSynced.indexOf(item) > -1
    }
    return false
  }

  remove(item: string) {
    if (this.selectedSynced) {
      const index = this.selectedSynced.indexOf(item)
      if (index >= 0) this.selectedSynced.splice(index, 1)
    }
  }

  doLoading() {
    if (!this.loaded) {
      this.getTokens()
    }
  }

  async getTokens() {
    this.loading = true
    const isAllowed: boolean = isAllowedToUseGenericRelationFilter({
      store: this.$store,
      route: this.$route,
      path: '/my/chargesession',
      flag: GENERIC_FILTER_CHARGING_HISTORY
    })
    let relationId
    let relationContactId
    if (isAllowed) {
      const selectedRelation = this.genericRelationFilter
      if (
        selectedRelation &&
        selectedRelation.Type === RelationTypes.relationContact
      ) {
        relationContactId = selectedRelation.Id
      } else {
        relationId = selectedRelation.Id
      }
    }
    const dt = (await new SubscriptionApi().list(
      relationContactId,relationId
    )) as DefaultResponseInterface<Tokens[]>
    this.loading = false
    this.loaded = true
    this.tokens = dt.data
  }

  @Watch('genericRelationFilter')
  genericRelationFilterChanged() {
    this.getTokens()
  }
}
